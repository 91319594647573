// variabili
$arancio-scuro: #d26d28;
$arancio-lemme: #F38A22;
$arancio-chiaro: #f4dbbb;
$giallo-chiaro: #FEF3E7;
$giallo-scuro: #f2d078;
$blu: #2254a0;

$blu-scuro: #1B439A;
$azzurro: #3CB8E8;

$lemmeaqua: #009DC5;

$bianco: #fff;
$grigio-chiaro: #F5F5F5;
$grigio-scuro: #B7B7B7;


$successo: #2E9E46;
$errore: #F53C3D;
$attenzione: #E0B137;


$azzurro-chiaro: #94d8f3;

$font-bigger: 36px;
$font-big: 22px;
$font-medium: 16px;
$font-small: 12px;


$bp-tema: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;


.mat-drawer-container {
  // background: blue;
}

