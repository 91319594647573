/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

//NGX-SHARE button
@import 'node_modules/ngx-sharebuttons/themes/default';

//PLYR video/audio player
@import "~plyr/dist/plyr.css";

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

@import 'theme';

//regole generali del tema
@import '~@angular/material/theming';

$custom-typography: mat.define-typography-config(
  $font-family: 'Montserrat',
  $body-1: mat.define-typography-level(18px, 24px, 400), // $headline: mat.define-typography-level(32px, 48px, 700),
  //$body-1: mat.define-typography-level(16px, 24px, 500)
);
@include mat.all-component-typographies($custom-typography);


:root {

  --plyr-audio-controls-background: #2254a0;
  --plyr-audio-control-color: #fff;
  --plyr-color-main: #df9765;

  .footer-app {
    --plyr-audio-controls-background: transparent;
    --plyr-audio-control-color: #fff;
    --plyr-color-main: $arancio-chiaro;
  }
}


html, body {
  height: 100%;
}

body {
  margin: 0;

}


body .mat-button.lemme {
  background: $arancio-lemme;
  border-radius: 1px;
  text-transform: uppercase;
  font-weight: normal;
  box-shadow: inset 0px 1px 3px 0px #fff, 2px 2px 3px 2px #333;
  padding: 3px 40px;
  color: #fff;
  font-size: $font-medium;

  &.mat-secondary {
    background-color: $blu;
  }
}

/*form default*/
.form-group {
  padding: 0 0 10px;
}

.mat-form-field {

  width: 100%;
  padding: 0;
  background: $grigio-chiaro;
  border-radius: 10px;

  overflow: hidden;

  .mat-form-field-wrapper {

    background: transparent;
    width: 100%;
    border: unset;

    padding: 5px 15px;

    .mat-form-field-flex {
      background: transparent;
      padding: 8px 0px;
    }

    .mat-form-field-label-wrapper {
      padding-top: 25px;
      top: -16px;

      .mat-form-field-label {
        font-size: $font-medium;
        transform: translateY(-20px);
        color: #000;
      }


    }

    input, input:focus  ,
    textarea, textarea:focus
    {
      background: transparent;
      outline: none;
      box-shadow: unset;
      border-bottom: 1px solid $grigio-scuro;
      border-radius: 0;

      padding: 8px 0;


    }

    textarea, textarea:focus {
      background: transparent;
      outline: none;
      box-shadow: unset;
      min-height: 120px;
      resize: none;
    }

    .mat-form-field-underline {
      display: none;
    }


    .mat-form-field-subscript-wrapper {
      margin-top: 3px;
    }


  }
}
.select-small{
  .mat-form-field {
    .mat-form-field-wrapper {
      padding: 0 5px;
      .mat-form-field-flex {
        padding: 3px ;
        .mat-form-field-infix{
          border: none ;
        }
      }
    }
  }
}
.cdk-global-overlay-wrapper {
  background-color: rgba(125, 125, 125, .7);

  .cdk-overlay-pane {


    padding: 15px;

    .mat-dialog-container {
      background-color: #fff;
      border-radius: 20px;
      overflow: hidden;
      position: relative;


      .titolo-content {
        padding-bottom: 10px;
        margin-bottom: 20px;
        border-bottom: 1px solid #999;

        .titolo {
          margin: 0;
          padding: 0 15px;

        }
      }
    }

    &.success-dialog {
      .mat-dialog-container {
        background-color: lighten($successo, 55%);

        .titolo, mat-icon {
          color: $successo;
        }
      }
    }

    &.standard-dialog {
      .mat-dialog-container {
        background-color: #fff;

        .titolo, mat-icon {
          color: $attenzione;
        }
      }
    }

    &.error-dialog {
      .mat-dialog-container {
        background-color: lighten($errore, 36%);

        .titolo, mat-icon {
          color: $errore;
        }
      }
    }
  }


}

.content-container {
  // padding: 30px;
  max-width: 980px;
  margin: 0 auto;

  .spacer {
    height: 30px;
  }

  .mat-card {

    margin-left: 15px;
    margin-right: 15px;

    box-shadow: none;
    border-radius: 30px;
    background-color: $azzurro-chiaro;
    overflow: hidden;
    padding: 30px;

    &.full {
      margin-left: 0;
      margin-right: 0;
    }

    &.flat {
      border-radius: 0;

    }

    mat-card-title {
      margin-bottom: 20px;
    }

    mat-card-content {
      font-size: .8em;
    }


    ngb-accordion {

      .accordion-item {
        overflow: hidden;
        border-radius: 15px;
        margin: 0 0 10px;

        border: 1px solid;
        border-color: darken($grigio-chiaro, 30%);


        h3{
          margin-bottom: 0;
        }
        .descrizione-breve{
          font-size: .8em;
        }
        .accordion-button {
          background-color: $grigio-chiaro;
          color: #000;

          &:focus {
            outline: none;
          }


        }
      }
    }

    mat-nav-list {
      mat-list-item {
        background-color: $grigio-chiaro;
        border-radius: 12px;
        margin-bottom: 10px;
        &:hover{
          background-color: darken($grigio-chiaro,5%);
        }
        mat-divider{
          display: none;
        }
      }
    }

  }

}


/*BOTTONI*/
.btn-lemme-full, .btn-lemme-link {
  font-size: 1.2em;
  width: 100%;
  display: block;
  text-transform: uppercase;

}

.btn-lemme-full {
  background-color: $arancio-lemme;
  color: #fff;
  border: none;
  border-radius: 15px;
  font-weight: bold;
  padding: 10px 20px;

  &:hover {
    color: #fff;
  }
}

.btn-lemme-link {
  color: $arancio-lemme;
  font-weight: lighter;
}
//upload foto
.upload-ext {
  text-align: center;
  position: relative;

  .remove-images {
    position: absolute;
    top: 0;
    right: 0;

    border: 1px solid $grigio-scuro;
    background: #fff;
    border-radius: 0;
    display: inline-block;
    line-height: 1;
    color: $errore;
  }


  .file-input-buttons {
    margin-top: 10px;

    .file-input-container {
      input[type="file"] {
        display: none;
      }


      .custom-file-upload {
        border: 1px solid $grigio-scuro;
        background: #fff;
        border-radius: 12px;
        display: inline-block;

        padding: 6px 12px;
        cursor: pointer;

        .icona, .testo {
          vertical-align: middle;
        }

        .testo {
          padding: 0 5px;
        }
      }
    }
  }


}
